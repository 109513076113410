import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useSubjectsList() {
  // Use toast
  const toast = useToast();

  const refSubjectListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    {
      key: 'createdAt',
      label: 'Created At',
      formatter: (value) => {
        return moment(value).format('L');
      },
      sortable: true,
    },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalSubjects = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refSubjectListTable.value ? refSubjectListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSubjects.value,
    };
  });

  const refetchData = () => {
    refSubjectListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter], () => {
    refetchData();
  });

  const fetchSubjects = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: '',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('app-roster/fetchSubjects', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalSubjects.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching subjects list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // archived, internal, public

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'subscriber') return 'primary';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'subscriber') return 'SubjectIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'SubjectIcon';
  };

  const resolveSubjectVisibilityVariant = (status) => {
    if (status === 'internal') return 'primary';
    if (status === 'public') return 'info';
    return 'primary';
  };

  const resolveSubjectStatusVariant = (status) => {
    if (status === 'created') return 'info';
    if (status === 'pending_approval') return 'primary';
    if (status === 'approved') return 'warning';
    if (status === 'live') return 'success';
    if (status === 'retired') return 'secondary';
    return 'primary';
  };

  const visibilityOptions = [
    { label: 'Archived', value: 'archived' },
    { label: 'Internal', value: 'internal' },
    { label: 'Public', value: 'public' },
  ];

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Live', value: 'live' },
    { label: 'Retired', value: 'retired' },
    { label: 'Archived', value: 'archived' },
  ];

  return {
    fetchSubjects,
    tableColumns,
    perPage,
    currentPage,
    totalSubjects,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSubjectListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveSubjectVisibilityVariant,
    resolveSubjectStatusVariant,
    refetchData,

    visibilityOptions,
    statusOptions,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
